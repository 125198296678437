export default function Icond2() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M9.30162 24.75H23.1158C23.4611 24.75 23.7437 24.4675 23.7437 24.1221V17.843C23.7437 17.4977 23.4611 17.2151 23.1158 17.2151C22.7704 17.2151 22.4878 17.4977 22.4878 17.843V23.4942H9.92953V17.843C9.92953 17.4977 9.64697 17.2151 9.30162 17.2151C8.95626 17.2151 8.6737 17.4977 8.6737 17.843V24.1221C8.6737 24.4675 8.95626 24.75 9.30162 24.75ZM8.04579 15.9593H24.3716C24.7169 15.9593 24.9995 15.6768 24.9995 15.3314V12.1919C24.9995 11.8465 24.7169 11.564 24.3716 11.564H8.04579C7.70043 11.564 7.41787 11.8465 7.41787 12.1919V15.3314C7.41787 15.6768 7.70043 15.9593 8.04579 15.9593ZM23.7437 14.7035H8.6737V12.8198H23.7437V14.7035Z"
                fill="black"
            />
            <path
                d="M16.2083 24.7498C16.5536 24.7498 16.8362 24.4672 16.8362 24.1219V12.1916C16.8362 11.8463 16.5536 11.5637 16.2083 11.5637C15.8629 11.5637 15.5804 11.8463 15.5804 12.1916V24.1219C15.5804 24.4672 15.8629 24.7498 16.2083 24.7498Z"
                fill="black"
            />
            <path
                d="M15.547 12.7779C15.9865 12.7779 16.3633 12.6523 16.6458 12.3698C17.8703 11.1453 15.9551 8.1314 15.3272 7.47209C14.8563 7.03256 14.2284 6.75 13.5377 6.75C12.8783 6.75 12.2504 7.00116 11.7481 7.47209C10.7748 8.44535 10.7748 10.0465 11.7481 11.0198C12.2504 11.5221 14.1656 12.7779 15.547 12.7779ZM13.5377 8.00581C13.883 8.00581 14.197 8.1314 14.4167 8.38256C15.1388 9.10465 16.0179 11.114 15.7354 11.4907C15.3586 11.7419 13.3807 10.8942 12.6272 10.1407C12.1248 9.63837 12.1248 8.85349 12.6272 8.35116C12.9097 8.1314 13.2237 8.00581 13.5377 8.00581Z"
                fill="black"
            />
            <path
                d="M17.0869 12.8201C18.4997 12.8201 20.3835 11.5642 20.8858 11.0933C21.3567 10.6224 21.6079 9.99448 21.6079 9.30378C21.6079 8.64448 21.3567 8.01657 20.8858 7.51424C19.9125 6.54099 18.3114 6.54099 17.3381 7.51424C17.0869 7.76541 17.0869 8.14215 17.3381 8.39331C17.5892 8.64448 17.966 8.64448 18.2172 8.39331C18.7195 7.89099 19.5044 7.89099 20.0067 8.39331C20.2579 8.64448 20.3835 8.95843 20.3835 9.27238C20.3835 9.61773 20.2579 9.93169 20.0067 10.1515C19.2846 10.8735 17.2753 11.7526 16.8985 11.4701C16.6474 11.2189 16.2706 11.2189 16.0195 11.4701C15.7683 11.7212 15.7683 12.1294 16.0195 12.3491C16.2706 12.6945 16.6474 12.8201 17.0869 12.8201Z"
                fill="black"
            />
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
        </svg>
    );
}
